import React, { useState, useEffect, useContext } from 'react';
import { SocketContext } from '../context/SocketContext';
import { Button, Checkbox, Form, Input, Typography } from 'antd';
import { Space, Table, Tag } from 'antd';
import { getBands } from '../helpers/getbands';
const { Title } = Typography
const BandList = ({ bands }) => {
    const [bandsData, setBands] = useState(bands)
    const { socket } = useContext(SocketContext)


    useEffect(() => {
        socket.on('current-bands', bands => {
            console.log(bands)
            setBands(bands)
        })
        return () => socket.off('current-bands')

    }, [socket])
    const handleChange = (e, id) => {
        setBands(bandsData => bandsData.map(b => {
            if (b.id === id) {
                b.name = e.target.value
            }
            return b
        }))

    }
    const handleOnBlur = (id, name) => {
        socket.emit('cambiar-nombre', { id, name })
    }
    const votar = (id) => {
        socket.emit('votar-banda', id)
    }
    const borrarBanda = (id) => {
        socket.emit('borrar-banda', id)
    }

    const crearRows = () => {

        return (

            bandsData.map(b => (
                < tr key={b.id}>
                    <td>
                        <Button onClick={() => votar(b.id)} type="primary"> +1</Button>
                    </td>
                    <td><Input onBlur={() => handleOnBlur(b.id, b.name)} onChange={(e) => handleChange(e, b.id)} value={b.name} /></td>
                    <td><Title level={3}>{b.votes}</Title></td>
                    <td><Button onClick={() => borrarBanda(b.id)} type="danger">Borrar</Button></td>

                </tr >
            )))
    }
    console.log("bands", bandsData)
    return (
        <div><table >
            <thead>
                <tr>
                    <th></th>
                    <th>Nombre</th>
                    <th>Votos</th>
                    <th>Borrar</th>
                </tr>
            </thead>
            <tbody>
                {crearRows(bandsData)}
            </tbody>
        </table></div>
    );
}

export default BandList;