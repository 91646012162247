import React, { useState, useEffect, useContext } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { LoginPage } from '../pages/LoginPage';
import { RegisterPage } from '../pages/RegisterPage';
import '../css/login-css.css'
import { AuthContext } from '../auth/AuthContext';
import { Spin } from "antd";



const AuthRouter = () => {
    const { auth, verificaToken } = useContext(AuthContext)
    const router = useNavigate();
    useEffect(() => {
        verificaToken();
    }, [])


    if (auth.checking) {
        return (<Spin />)
    }
    if (auth.logged) {
        router('/chat')
    }
    return (
        <div className="limiter">
            <div className="container-login100">
                <div className="wrap-login100  ">
                    <Routes>
                        <Route path="login" element={<LoginPage />} />
                        <Route path="register" element={<RegisterPage />} />

                        <Route path="*" element={<Navigate to="/auth/login" />} />
                    </Routes>
                </div>
            </div>
        </div>
    );
};

export default AuthRouter;