import React, { useState, useContext } from 'react'
import { SidebarChatItem } from './SidebarChatItem';
import { ChatContext } from '../context/chat/ChatContext';
import { AuthContext } from '../auth/AuthContext';
import { SocketContext } from '../context/SocketContext';

export const Sidebar = () => {
    const { socket } = useContext(SocketContext)
    const { chatState } = useContext(ChatContext)
    const { auth } = useContext(AuthContext)



    return (
        <div className="inbox_chat">
            {chatState.usuarios.filter(u => u.uid !== auth.uid).map(usuario => (
                <SidebarChatItem key={usuario.uid} usuario={usuario} />
            ))}

            {/*Espacio extra para scroll */}
            <div className="extra_space"></div>


        </div>
    )
}
