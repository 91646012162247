import { types } from "../../types/types";

export const chatReducer = (state, action) => {

    switch (action.type) {
        case types.getUsuarios:
            return {
                ...state,
                usuarios: [...action.payload]
            }
        case types.activarChat:
            if (state.chatActivo === action.payload) return { ...state }
            return {
                ...state,
                chatActivo: action.payload,
                mensajes: []
            }
        case types.nuevoMensaje:
            if (state.chatActivo === action.payload.de || state.chatActivo === action.payload.para) {

                var hash = {};
                var array = [...state.mensajes, action.payload]
                array = array.filter(function (current) {
                    var exists = !hash[current._id];
                    hash[current._id] = true;
                    return exists;
                })

                console.log(state.chatActivo === action.payload.de, state.chatActivo === action.payload.para)


                return {
                    ...state,
                    mensajes: [...array]
                }
            } else {
                return { ...state }
            }
        case types.setMensajes:
            {
                return {
                    ...state,
                    mensajes: [...action.payload]
                }
            }
        case types.resetState: {
            return {
                uid: '',
                chatActivo: null,
                usuarios: [],
                mensajes: []
            }
        }
        default:
            return state;
    }

}