import React, { useState, useEffect, useContext } from 'react'
import { Typography, Row, Col, List, Card, Tag, Divider, Spin } from 'antd'
import { useHideMenu } from '../hooks/useHideMenu';
import { SocketContext } from '../context/SocketContext';
import { getUltimos } from '../helpers/getUltimos';
const { Title, Text } = Typography

export const Cola = () => {
    useHideMenu(true)
    const { socket } = useContext(SocketContext)
    const [refresh, setRefresh] = useState(true)
    const [jugadores, setJugadores] = useState([])
    useEffect(() => {
        getUltimos().then(r => {
            console.log(r)
            setRefresh(false)
            setJugadores(r.ultimos)
        })
    }, [])
    useEffect(() => {
        socket.on('ticket-asignado', players => {
            setJugadores(players)
        })
        return () => {
            socket.off('ticket-asigando')
        }
    }, [socket])

    if (refresh) {
        return (
            <Spin />
        )
    }

    return (
        <>
            <Title>En espera</Title>
            <Row>
                <Col span={12}>
                    <List
                        dataSource={jugadores.slice(0, 3)}
                        renderItem={item => (
                            <List.Item>
                                <Card style={{ width: 300, marginTop: 16 }}
                                    actions={[
                                        <Tag color="volcano">{item.agente}</Tag>,
                                        <Tag color="magenta">{item.escritorio}</Tag>
                                    ]}
                                >
                                    Jugador N° {item.number}
                                </Card>
                            </List.Item>
                        )}
                    />
                </Col>
                <Col span={12}>
                    <Divider>Historial</Divider>
                    <List
                        dataSource={jugadores.slice(3)}
                        renderItem={item => (
                            <List.Item>
                                <List.Item.Meta
                                    title={`Jugador N° ${item.number}`}
                                    description={<>
                                        <Text type="secondary"> En el box: </Text>
                                        <Tag color="magenta">{item.escritorio}</Tag>
                                        <Text type="secondary"> Con el agente: </Text>
                                        <Tag color="volcano">{item.agente}</Tag>
                                    </>}
                                />
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>
        </>
    )
}
