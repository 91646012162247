import React, { useContext } from 'react'
import { AuthContext } from '../auth/AuthContext';
import { SocketContext } from '../context/SocketContext';
import { ChatContext } from '../context/chat/ChatContext';

export const SearchBox = () => {

    const { auth, logout } = useContext(AuthContext)
    const { socket } = useContext(SocketContext)
    const socketOff = () => {
        console.log('saliendo')
        socket.disconnect();

        logout();
    }
    return (

        <div className="headind_srch">
            <div className="recent_heading mt-2">
                <h4>{auth.name}</h4>
            </div>
            <div className="srch_bar">
                <div className="stylish-input-group">
                    <button className="btn text-danger" htmlType="button" onClick={() => { socketOff() }}>
                        Salir
                    </button>
                </div>
            </div>
        </div>

    )
}
