import React, { useState, useContext, useEffect } from 'react'
import { Row, Col, Typography, Button, Divider } from 'antd'
import { CloseCircleOutlined, RightOutlined } from '@ant-design/icons'
import { useHideMenu } from '../hooks/useHideMenu';
import { getUsuarioStorage } from '../helpers/getUsuarioStorage';
import { useNavigate } from 'react-router-dom';
import { SocketContext } from '../context/SocketContext';
const { Title, Text } = Typography
export const Box = () => {
    const navigate = useNavigate();
    const [usuario] = useState(getUsuarioStorage())
    const [player, setPlayer] = useState(null)
    useHideMenu(false)
    const { socket } = useContext(SocketContext)
    const salir = () => {
        localStorage.clear();
        navigate('/')
    }
    const siguiente = () => {
        socket.emit('siguente-player', usuario, (nuevoPlayer) => {
            console.log(nuevoPlayer)
            setPlayer(nuevoPlayer)
        })
    }

    useEffect(() => {
        if (!usuario.agente || !usuario.escritorio) {
            navigate('/')
        }
    }, [usuario, navigate])



    return (

        <>
            <Row>
                <Col span={20}>
                    <Title level={2} >{usuario.agente}</Title>
                    <Text>Usted esta trabajando en el box: </Text>
                    <Text type="success">{usuario.escritorio}</Text>
                </Col>
                <Col span={4} align="rigth">
                    <Button type='danger' shape='round' onClick={() => salir()}>
                        <CloseCircleOutlined />
                        Salir
                    </Button>
                </Col>
            </Row>
            <Divider />
            <Row>
                <Col >
                    {player ? (
                        <>
                            <Text>Estas esperando al jugador:</Text>
                            <Text type="danger" style={{ fontSize: 20 }}>{player?.number}</Text>
                        </>) : (<Text>No hay siguientes</Text>)}
                </Col>
            </Row>
            <Row>
                <Col offset={18} span={6} align='rigth'>
                    <Button shape='round' type="primary" onClick={siguiente}>
                        <RightOutlined />Siguiente</Button>
                </Col>
            </Row>

        </>
    )
}
