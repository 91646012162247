import { useMemo, useState, useEffect, useContext } from 'react';
import io from 'socket.io-client'
import { AuthContext } from '../auth/AuthContext';
import { ChatContext } from '../context/chat/ChatContext';
import { scrollToBottomAnimated } from '../helpers/scrollToBottom';
import { types } from '../types/types';

export const useSocket = (serverPath) => {

    const [online, setOnline] = useState(false)
    const { auth } = useContext(AuthContext)
    const { dispatch } = useContext(ChatContext);
    console.log('server connecting')
    const token = localStorage.getItem('token')

    const socket = useMemo(() => io.connect(serverPath, {
        transports: ['websocket'],
        autoConnect: true,
        forceNew: true,
        query: {
            'x-token': token
        }
    }), [serverPath, token]);

    useEffect(() => {
        setOnline(socket.connected)
    }, [socket])

    useEffect(() => {
        socket.on('connect', () => {
            setOnline(true)
        })
    }, [socket])
    useEffect(() => {
        socket.on('disconnect', () => {
            setOnline(false)
        })
    }, [socket])

    useEffect(() => {
        socket?.on('lista-usuarios', usuarios => {
            dispatch({
                type: types.getUsuarios,
                payload: usuarios
            })
        })
    }, [socket, dispatch])

    useEffect(() => {
        socket?.on('mensaje-personal', (mensaje) => {

            dispatch({
                type: types.nuevoMensaje,
                payload: mensaje
            })
            scrollToBottomAnimated('mensajes')
        })
    }, [socket, dispatch])








    return {
        socket,
        online
    }


}