import React from 'react'
import { fechaFormat } from '../helpers/momentFormat';

export const OutgoingMessage = ({ msg }) => {
    return (
        <div className="outgoing_msg">
            <div className="sent_msg">
                <p>{msg.mensaje}</p>
                <span className="time_date"> {fechaFormat(msg.createdAt)}</span>
            </div>
        </div >
    )
}
