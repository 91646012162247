import React, { useContext, useState } from 'react'
import { Button, Col, Row, Typography } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useHideMenu } from '../hooks/useHideMenu';
import { SocketContext } from '../context/SocketContext';
const { Title, Text } = Typography
export const CrearPlayer = () => {
    useHideMenu(false)
    const { socket } = useContext(SocketContext)
    const [player, setPlayer] = useState(null)
    const nuevoJugador = () => {
        socket.emit('solitar-ticket', null, (newPlayer) => {
            setPlayer(newPlayer)
        })
    }
    return (
        <>
            <Row>
                <Col span={14} offset={6} align="center">
                    <Title level={3}>Nuevo Jugador</Title>
                    <Button onClick={nuevoJugador} size="large" type="primary" shape='round' icon={<DownloadOutlined />} >
                        Nuevo Jugador
                    </Button>
                </Col>
            </Row>
            {player && (
                <Row style={{ marginTop: "100px" }}>
                    <Col span={14} offset={6} align="center">
                        <Text level={2}>Su número: </Text>
                        <br />
                        <Text type='success' style={{ fontSize: 55 }}>{player?.number}</Text>
                    </Col>
                </Row>
            )}
        </>
    )
}
