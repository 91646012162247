import React from 'react'
import CustomLayout from '../Layout/Layout';
import {
    BrowserRouter,
    Routes,
    Route,
    Link
} from "react-router-dom";

export const RouterPage = () => {
    return (

        <BrowserRouter>
            <CustomLayout>

            </CustomLayout>
        </BrowserRouter>


    )
}
