import React, { createContext, useCallback, useContext, useState } from 'react';
import { fetchConToken, fetchSinToken } from '../helpers/fetch';
import { ChatContext } from '../context/chat/ChatContext';
import { types } from '../types/types';
export const AuthContext = createContext();

const initialState = {
    uid: null,
    checking: true,
    logged: false,
    name: null,
    email: null,
}


export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(initialState)
    const { dispatch } = useContext(ChatContext)
    const login = async (email, password) => {
        const resp = await fetchSinToken('api/login', { email, password }, 'POST')

        if (resp.ok) {
            localStorage.setItem('token', resp.token);
            const { usuario } = resp;
            setAuth({
                uid: usuario.uid,
                checking: false,
                logged: true,
                name: usuario.nombre,
                email: usuario.email
            })
        }
        return resp.ok;
    }
    const register = async (nombre, email, password, rol) => {
        const resp = await fetchSinToken('api/login/new', { nombre, email, password, rol }, 'POST')

        if (resp.ok) {
            localStorage.setItem('token', resp.token);
            const { usuario } = resp;
            setAuth({
                uid: usuario.uid,
                checking: false,
                logged: true,
                name: usuario.nombre,
                email: usuario.email
            })
        }
        return resp.ok;
    }
    const verificaToken = useCallback(async () => {
        const token = localStorage.getItem('token')
        if (!token) {
            setAuth({
                uid: null,
                checking: false,
                logged: false,
                name: null,
                email: null,
            })
        }
        const resp = await fetchConToken('api/login/renew')
        if (resp.ok) {

            localStorage.setItem('token', resp.token);
            const { usuario } = resp;
            setAuth({
                uid: usuario.uid,
                checking: false,
                logged: true,
                name: usuario.nombre,
                email: usuario.email
            })

        } else {
            setAuth({
                uid: null,
                checking: false,
                logged: false,
                name: null,
                email: null,
            })

        }
        return resp.ok;

    }, [])
    const logout = () => {

        localStorage.removeItem('token')
        dispatch({
            type: types.resetState
        })
        setAuth({

            checking: false,
            logged: false,

        })


    }
    return (
        <AuthContext.Provider value={{
            auth,
            login,
            register,
            verificaToken,
            logout
        }}>
            {children}
        </AuthContext.Provider>
    )
}
