import React, { useState, useContext } from 'react';
import { Typography } from 'antd';
import { Button, Checkbox, Form, Input } from 'antd';
import { SocketContext } from '../context/SocketContext';
const { Title } = Typography;
const BandAdd = () => {
    const [name, setName] = useState("")
    const { socket } = useContext(SocketContext)

    const onFinish = (value) => {
        if (name.trim().length > 0) {
            socket.emit('nueva-banda', { name: name })
            setName('')
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    const onSubmit = (e) => {
        e.preventDefault()

    }

    return (
        <>
            <Title level={3} >Agregar Participante</Title>
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Nuevo Cosplayer"
                    name="name"
                    rules={[{ required: true, message: 'Ingrese un nombre' }]}
                >
                    <Input value={name} onChange={e => setName(e.target.value)} className='form-control' placeholder='nuevo nombre' />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                        Añadir Cosplayer
                    </Button>
                </Form.Item>
            </Form>


        </>);
}

export default BandAdd;