import React, { useState, useEffect } from 'react';
import { Button, Typography, Form, Input, InputNumber, Divider } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useHideMenu } from '../hooks/useHideMenu';
import { getUsuarioStorage } from '../helpers/getUsuarioStorage';


const { Title, Text } = Typography
const Ingresar = () => {
    useHideMenu(false)
    const [usuario] = useState(getUsuarioStorage())
    const navigate = useNavigate();
    const onFinish = ({ agente, escritorio }) => {
        console.log(agente, escritorio)
        localStorage.setItem('agente', agente)
        localStorage.setItem('escritorio', escritorio)
        navigate('/box');
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    useEffect(() => {
        console.log(usuario)
        if (usuario.agente && usuario.escritorio) {
            navigate('/box')
        }
    }, [usuario, navigate])


    return (<>
        <Title level={2}>Ingresar</Title>
        <Text>Ingrese su nombre y número de box</Text>
        <Divider />
        <Form
            name="basic"
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 12,
            }}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                label="Nombre del agente"
                name="agente"
                rules={[
                    {
                        required: true,
                        message: 'Ingrese su nombre!',
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Box number"
                name="escritorio"
                rules={[
                    {
                        required: true,
                        message: 'Ingrese su Box',
                    },
                ]}
            >
                <InputNumber min={1} max={30} />
            </Form.Item>



            <Form.Item
                wrapperCol={{
                    offset: 2,
                    span: 16,
                }}
            >
                <Button type="primary" shape="round" htmlType="submit">
                    <SaveOutlined /> Ingresar
                </Button>
            </Form.Item>
        </Form>
    </>);
}

export default Ingresar;




