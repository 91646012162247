import React, { useContext, useState, useEffect } from 'react'
import { ChatSelect } from '../components/ChatSelect'
import { InboxPeople } from '../components/InboxPeople'
import { Menssages } from '../components/Menssages'
import "../css/chat-css.css"
import { ChatContext } from '../context/chat/ChatContext';

export const ChatPage = () => {
    const { chatState } = useContext(ChatContext)
    return (
        <div className="messaging">
            <div className="inbox_msg">

                {/*Inbox people inicio */}
                <InboxPeople />
                {/*Inbox people Fin */}

                {chatState.chatActivo ? <Menssages /> : <ChatSelect />}




            </div>


        </div>
    )
}
