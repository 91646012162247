import React, { useContext, useEffect } from 'react';
import { SocketContext } from '../context/SocketContext';
import { BarController, BarElement, CategoryScale, Chart, LinearScale } from 'chart.js';
import { getBands } from '../helpers/getbands';
Chart.register(BarElement, BarController, CategoryScale, LinearScale);

export const BandChart = () => {

    const { socket } = useContext(SocketContext);
    useEffect(() => {
        getBands().then(r => {
            console.log(r)
            crearGrafica(r.bands)
        })
    }, [])

    useEffect(() => {
        socket.on('current-bands', (bands) => {
            crearGrafica(bands);
        });
    }, [socket])

    let myChart;
    const crearGrafica = (bands = []) => {
        const ctx = document.getElementById('myChart');
        if (myChart) {
            myChart.destroy();
        }
        myChart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: bands.map(band => band.name),
                datasets: [{
                    label: '# of Votes',
                    data: bands.map(band => band.votes),
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)'
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)'
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                indexAxis: 'y',
                elements: {
                    bar: {
                        borderWidth: 2,
                    }
                },
                responsive: true,
                plugins: {
                    legend: {
                        position: 'right',
                    },
                    title: {
                        display: true,
                        text: 'Chart.js Horizontal Bar Chart'
                    }
                }
            }
        });
    }

    return (
        <canvas id="myChart" height={50}></canvas>
    )
}