import React, { useState, useContext } from 'react'
import { Row, Col, Button } from 'antd';
import { AuthContext } from '../auth/AuthContext';
import { ChatContext } from '../context/chat/ChatContext';
import { SocketContext } from '../context/SocketContext';

export const SendMenssage = () => {
    const { socket } = useContext(SocketContext)
    const { auth } = useContext(AuthContext);
    const { chatState, dispatch } = useContext(ChatContext)
    const [mensaje, setMensaje] = useState('')
    const onSubmit = e => {
        e.preventDefault();
        if (mensaje.length === 0) return;



        socket.emit('mensaje-personal', {
            de: auth.uid,
            para: chatState.chatActivo,
            mensaje: mensaje
        })

        setMensaje('')

    }
    return (
        <form onSubmit={onSubmit}>
            <div className="type_msg">
                <div className="input_msg_write ">
                    <input value={mensaje} onChange={(e) => setMensaje(e.target.value)} type="text" className="write_msg" placeholder="Mensaje..." />
                </div>
                <div className="text-center">
                    <Button className="msg_send_btn " htmlType="submit">
                        enviar
                    </Button>
                </div>
            </div>
        </form>
    )
}
