import React, { useContext } from 'react'
import { ChatContext } from '../context/chat/ChatContext';
import { fetchConToken } from '../helpers/fetch';
import { types } from '../types/types';
import { scrollToBottom } from '../helpers/scrollToBottom';

export const SidebarChatItem = ({ usuario }) => {
    const { chatState, dispatch } = useContext(ChatContext)
    const { chatActivo } = chatState

    const setUsuario = async () => {
        dispatch({
            type: types.activarChat,
            payload: usuario.uid
        })

        //Cargar los mensajes del chat
        await fetchConToken(`api/mensajes/${usuario.uid}`).then(r => {
            dispatch({
                type: types.setMensajes,
                payload: r.mensajes
            })

        })
        scrollToBottom('mensajes')


    }
    return (



        <div className={`chat_list ${usuario.uid === chatActivo && 'active_chat'}`} onClick={() => setUsuario()}>
            <div className="chat_people">
                <div className="chat_img">
                    <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil" />
                </div>
                <div className="chat_ib">
                    <h5>{usuario.nombre}</h5>
                    {usuario.online ? (
                        <span className="text-success">Online</span>
                    ) : (
                        <span className="text-danger">Offline</span>
                    )}
                </div>
            </div>
        </div>



    )
}
