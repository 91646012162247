import React, { useContext } from 'react'
import { IncomingMessage } from './IncomingMessage'
import { OutgoingMessage } from './OutgoingMessage'
import { SendMenssage } from './SendMenssage'
import { ChatContext } from '../context/chat/ChatContext';
import { AuthContext } from '../auth/AuthContext';

export const Menssages = () => {
    const { chatState } = useContext(ChatContext)
    const { auth } = useContext(AuthContext)

    return (
        <div className="mesgs">


            <div className="msg_history" id="mensajes">

                {chatState.mensajes.map(msg => (
                    msg.para === auth.uid ? <IncomingMessage key={msg._id} msg={msg} /> : <OutgoingMessage msg={msg} key={msg._id} />
                ))}

            </div>

            <SendMenssage />


        </div>
    )
}
