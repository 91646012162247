import React, { createContext, useContext, useEffect } from "react";
import { useSocket } from "../hooks/useSocket";
import { AuthContext } from '../auth/AuthContext';

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const { auth } = useContext(AuthContext)
  //http://54.226.254.118:5501
  //

  useEffect(() => {

  }, [auth])

  const { socket, online } = useSocket(
    process.env.REACT_APP_API_URL
  );
  return (
    <SocketContext.Provider value={{ socket, online }}>
      {children}
    </SocketContext.Provider>
  );
};
