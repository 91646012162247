import React, { useState, useEffect, useContext } from 'react'
import { useHideMenu } from '../hooks/useHideMenu';
import '../css/login-css.css'
import { Row, Col, Button, Spin } from 'antd';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { AuthContext } from '../auth/AuthContext';
import Swal from 'sweetalert2'
export const LoginPage = () => {
    const { login } = useContext(AuthContext)
    const router = useNavigate()
    const [loading, setLoading] = useState(false)
    useHideMenu(false)
    const [form, setForm] = useState({
        email: '',
        password: '',
        rememberme: true
    })
    useEffect(() => {
        const email = localStorage.getItem('email')
        if (email) {
            setForm((form) => ({
                ...form,
                email,
                rememberme: true,

            }))
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        setLoading(false)
    }, [form])


    const onChange = e => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }
    const toogleCheck = () => {
        setForm({
            ...form,
            rememberme: !form.rememberme
        })
    }
    const onSubmit = async (e) => {
        e.preventDefault();

        (form.rememberme) ?
            localStorage.setItem('email', form.email)
            :
            localStorage.removeItem('email')
        setLoading(true)

        await login(form.email, form.password).then(resp => {
            if (!resp) {
                Swal.fire('Error', 'Verifique las credenciales', 'error')

            } else {

                Swal.fire('Bienvenido', 'Ingreso correcto', 'success')
                router('/chat')

            }
        })
        setLoading(false)


    }

    const validacion = () => {
        return (form.email.length > 0 && form.password.length > 0) ? true : false
    }

    return (
        <form className="login100-form validate-form flex-sb flex-w" onSubmit={(e) => onSubmit(e)}>
            <span className="login100-form-title mb-3">
                Chat - Ingreso
            </span>

            <div className="wrap-input100 validate-input mb-3">
                <input value={form.email} onChange={onChange} className="input100" type="email" name="email" placeholder="Email" />
                <span className="focus-input100"></span>
            </div>


            <div className="wrap-input100 validate-input mb-3">
                <input value={form.password} onChange={onChange} className="input100" type="password" name="password" placeholder="Password" />
                <span className="focus-input100"></span>
            </div>

            <Row className=" mb-3">
                <Col span={12} onClick={() => toogleCheck()}>
                    <input readOnly checked={form.rememberme} className="input-checkbox100" id="ckb1" type="checkbox" name="rememberme" />
                    <label className="label-checkbox100">
                        Recordarme
                    </label>
                </Col>

                <Col span={12} className="text-right">
                    <Link to={'/auth/register'} className="txt1">

                        Nueva cuenta?

                    </Link>
                </Col>
            </Row>

            <div className="container-login100-form-btn m-t-17">
                <Button className="login100-form-btn" htmlType="submit" disabled={!validacion() || loading}>
                    {loading ? <Spin /> : 'Ingresar'}
                </Button>
            </div>

        </form>
    )
}
