import React, { useContext, useEffect, useState } from 'react'
import { useHideMenu } from '../hooks/useHideMenu';
import '../css/login-css.css'
import { Row, Col, Button } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../auth/AuthContext';
import Swal from 'sweetalert2'
import { Spin } from 'antd';
export const RegisterPage = () => {
    useHideMenu(false)
    const [loading, setLoading] = useState(false)
    const router = useNavigate;
    const { register } = useContext(AuthContext)
    const [form, setForm] = useState({
        email: '',
        password: '',
        nombre: '',
        rol: 'cliente'
    })

    const onChange = e => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
        setLoading(false)
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)

        await register(form.nombre, form.email, form.password, form.rol).then(resp => {
            if (!resp) {
                Swal.fire('Error', 'Verifique las credenciales', 'error')

            } else {

                Swal.fire('Bienvenido', 'Ingreso correcto', 'success')
                router('/chat')
            }
            setLoading(false)


        })

    }

    const validacion = () => {
        return (form.email.length > 0 && form.password.length > 0 && form.nombre.length > 0) ? true : false
    }



    return (
        <form className="login100-form validate-form flex-sb flex-w" onSubmit={(e) => onSubmit(e)}>
            <span className="login100-form-title mb-3">
                Chat - Registro
            </span>

            <div className="wrap-input100 validate-input mb-3">
                <input value={form.nombre} onChange={onChange} className="input100" type="text" name="nombre" placeholder="Nombre" />
                <span className="focus-input100"></span>
            </div>


            <div className="wrap-input100 validate-input mb-3">
                <input value={form.email} onChange={onChange} className="input100" type="email" name="email" placeholder="Email" />
                <span className="focus-input100"></span>
            </div>


            <div className="wrap-input100 validate-input mb-3">
                <input value={form.password} onChange={onChange} className="input100" type="password" name="password" placeholder="Password" />
                <span className="focus-input100"></span>
            </div>

            <Row className="mb-3">
                <Col span={24} className="text-right">
                    <Link to={'/auth/login'} className="txt1">

                        Ya tienes cuenta?

                    </Link>
                </Col>
            </Row>

            <div className="container-login100-form-btn m-t-17">
                <Button className="login100-form-btn" htmlType="submit" disabled={!validacion() || loading}>
                    {loading ? <Spin /> : 'Crear cuenta'}
                </Button>
            </div>

        </form>
    )
}
