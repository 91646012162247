import React, { useState, useEffect, useContext } from 'react';
import BandList from '../components/BandList';
import { SocketContext } from '../context/SocketContext';
import { BandChart } from '../components/BandChart';
import BandAdd from '../components/BandAdd';

import { Row, Col, Spin } from 'antd';
import { useHideMenu } from '../hooks/useHideMenu';
import { getBands } from '../helpers/getbands';

export const Cosplayer = () => {
    useHideMenu(false)
    const [bandsData, setBands] = useState([])
    const { socket, online } = useContext(SocketContext)

    useEffect(() => {
        getBands().then(r => {
            console.log(r)
            setBands(r.bands)
        })
    }, [])


    if (bandsData.length === 0) {
        return (
            <Spin />
        )
    }



    return (
        <div className="container">

            <div className="alert">
                <p>Service status:
                    {online ? (
                        <span className="text-success">Online</span>
                    ) : (
                        <span className="text-danger">Offline</span>
                    )}
                </p>
            </div>

            <h1>Votaciones</h1>
            <hr />
            <Row>
                <Col span={24}>
                    <BandChart />
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <BandList bands={bandsData} />
                </Col>
                <Col span={8}><BandAdd /></Col>
            </Row>


        </div>
    )
}
