import React from 'react'

export const ChatSelect = () => {
    return (
        <div className='center-item'>
            <div className='alert-info'>
                <hr />
                <h3>Seleccione una persona <span>Para comenzar una conversación</span></h3>

            </div>
        </div>
    )
}
