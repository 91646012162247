import React from 'react';
import { AuthProvider } from './auth/AuthContext';
import { ChatProvider } from './context/chat/ChatContext';
import { SocketProvider } from './context/SocketContext';
import { UiProvider } from './context/UiContext';
import { RouterPage } from './pages/RouterPage';
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es')
function TicketApp() {
  return (
    <ChatProvider>
      <AuthProvider>
        <SocketProvider>
          <UiProvider>
            <RouterPage />
          </UiProvider>
        </SocketProvider>
      </AuthProvider>
    </ChatProvider>
  );
}

export default TicketApp;
