import {
    ContactsTwoTone,
    CrownTwoTone,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    MessageTwoTone,
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Switch, Spin } from 'antd';
import React, { useState, useEffect, useContext } from 'react';
import { NavLink, Routes, Route, Navigate, useMatch, useResolvedPath, useLocation } from "react-router-dom";
import Ingresar from '../pages/Ingresar';
import { CrearPlayer } from '../pages/CrearPlayer';
import { Cola } from '../pages/Cola';
import { Box } from '../pages/Box';
import { UiContext } from '../context/UiContext';
import { Cosplayer } from '../pages/Cosplayer';
import { ChatPage } from '../pages/ChatPage';
import { LoginPage } from '../pages/LoginPage';
import { RegisterPage } from '../pages/RegisterPage';
import AuthRouter from '../routes/AuthRouter';
import { AuthContext } from '../auth/AuthContext';
const { Header, Sider, Content } = Layout;

const CustomLayout = () => {
    const { pathname } = useLocation()
    const [collapsed, setCollapsed] = useState(true);
    const [selectedKey, setSelectedKey] = useState(null)
    const { ocultarMenu } = useContext(UiContext)
    const { auth, verificaToken } = useContext(AuthContext)
    useEffect(() => {
        verificaToken();
    }, [])
    useEffect(() => {
        console.log("p", pathname)
        switch (pathname) {

            case '/': {
                setSelectedKey('1')
                break;
            }
            case '/box': {
                setSelectedKey('1')
                break;
            }
            case '/cola': {
                setSelectedKey('2')
                break;
            }
            case '/crear-player': {
                setSelectedKey('3')
                break;
            }
            case '/cosplayer': {
                setSelectedKey('4')
                break;
            }
            case '/auth/login': {
                setSelectedKey('5')
                break;
            }
            case '/chat': {
                setSelectedKey('5')
                break;
            }
            default:
                setSelectedKey('1')
                break;
        }
    }, [pathname, auth])
    if (!selectedKey) { return null }

    return (
        <Layout style={{ height: "100vh" }}>
            <Sider collapsible collapsed={collapsed} hidden={ocultarMenu}>
                <h1 style={{ margin: 0, color: '#fff', height: "60px", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Blade Link</h1>
                <Menu
                    theme="dark"
                    mode="inline"
                    defaultSelectedKeys={['1']}
                    selectedKeys={[selectedKey]}>

                    <Menu.Item key="1" icon={<UserOutlined />}>
                        <NavLink to="/" >Ingresar</NavLink>
                    </Menu.Item>
                    <Menu.Item key="2" icon={<VideoCameraOutlined />}>
                        <NavLink to="/cola">Cola</NavLink>
                    </Menu.Item>
                    <Menu.Item key="3" icon={<ContactsTwoTone />}>
                        <NavLink to="/crear-player"> Crear Player</NavLink>
                    </Menu.Item>
                    <Menu.Item key="4" icon={<CrownTwoTone />}>
                        <NavLink to="/cosplayer"> Votación Cosplayer</NavLink>
                    </Menu.Item>

                    <Menu.Item key="5" icon={<MessageTwoTone />}>

                        <NavLink to="/auth"> Chat</NavLink>

                    </Menu.Item>


                </Menu>
            </Sider>
            <Layout className="site-layout">
                <Header
                    className="site-layout-background"
                    style={{
                        padding: 0,
                        paddingLeft: 20
                    }}
                    hidden={ocultarMenu}
                >   <div>

                        {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                            className: 'trigger',
                            onClick: () => setCollapsed(!collapsed),
                        })}
                    </div>
                </Header>
                <Content
                    className="site-layout-background"
                    style={{
                        margin: '24px 16px',
                        padding: 24,
                        minHeight: 280,

                    }}
                >

                    <Routes>
                        <Route path="/" element={<Ingresar />} />
                        <Route path="/crear-player" element={<CrearPlayer />} />
                        <Route path="/cola" element={<Cola />} />
                        <Route path="/box" element={<Box />} />
                        <Route path="/cosplayer" element={<Cosplayer />} />
                        {auth.logged && <Route path="/chat" element={<ChatPage />} />}

                        <Route path="/auth/*" element={<AuthRouter />} />

                        <Route
                            path="*"
                            element={<Navigate to="/" replace />}
                        />
                    </Routes>

                </Content>
            </Layout>
        </Layout>
    );
};

export default CustomLayout;